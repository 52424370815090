$(function () {
    var toggles = $('.menu__link');
    var subMenus = $('.menu__sub');

    toggles.on('click', function () {
        var toggle = $(this);
        if(toggle.hasClass('menu__link--active')) {
            closeAllSubmenu();
        } else {
            closeAllSubmenu();
            toggle.addClass('menu__link--active');
            toggle.parent().find('.menu__sub').addClass('menu__sub--active');
        }
    });

    function closeAllSubmenu() {
        toggles.removeClass('menu__link--active');
        subMenus.removeClass('menu__sub--active');
    }
});

$(function () {
    var menu = $('.menu');
    var header = $('.header');
    $('.hamburger').on('click', function () {
        $(this).toggleClass('hamburger--active');
        menu.toggleClass('menu--open');
        header.toggleClass('header--menu-open');
    })
});


$(function () {
    var lastPosition = 0;
    var header = $('.header');
    var startScrollHeight = header.find('.header__line--contacts').height();

    $(window).on("scroll", function () {
        var position = $(this).scrollTop();
        if (position < lastPosition) {
            scrollEventTop();
        } else {
            scrollEventBottom();
        }
        lastPosition = position;
    }).scroll();

    function scrollEventTop() {
        if ($(window).scrollTop() < startScrollHeight && $(window).width() <= 1200) {
            header.removeClass('header--sticky');
        }
    }

    function scrollEventBottom() {
        if ($(window).scrollTop() > startScrollHeight && $(window).width() <= 1200) {
            header.addClass('header--sticky');
        }
    }
}());
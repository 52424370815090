$(function () {
    $('[data-toggle]').each(function(el) {
        var toggle = $(this).find('[data-toggle-target]');
        var toggleClass = toggle.data('toggleClass');

        var target = $($(this).find(toggle.data('toggleTarget')));
        var targetClass = toggle.data('toggleTargetClass');

        toggle.on('click', function () {
            $(this).toggleClass(toggleClass);
            target.toggleClass(targetClass);
        })
    })
});
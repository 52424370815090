$(function () {
    let main = $('main');
    $('.slider__page-scroll').on('click', function () {
        $('html, body').animate({
            scrollTop: (main.offset().top)
        }, 1000);
    });

    $('.button--scroll-up').on('click', function () {
        $('html, body').animate({
            scrollTop: ($('body').offset().top)
        }, 500);
    })
});
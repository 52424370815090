$(function () {
    var form = $('.form.form--search');
    var searchButton = $('.button.button--search');
    var close = $('.form__close');

    searchButton.on('click', toggle);
    close.on('click', toggle);

    function toggle() {
        form.toggleClass('form--active');
        searchButton.toggleClass('button--hidden');
    }
});
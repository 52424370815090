$(function () {
	let sectionFilter  = $('.section.section--filter');
	if (!sectionFilter.length) return;

	let tabFilter = $('[data-tabs-filter]');
	let tab = tabFilter.find('[data-tab-active]');
	let filterTabs = $('.filter-tabs .tabs__toggle-list');
	let tabActive = filterTabs.find('[data-tab-active].tabs__toggle-item--active').data('tab-active');
	let select = $("select");
	let selectFilterFaculty = $("#faculty");
	let selectFilterTraining = $("#training");
	let result = $('.training');

	function selectStyle() {
		let _dropdown;
		let settings = {
			cursorcolor: "#0e67b3",
			cursorwidth: "6px",
			cursorborder: "none",
			cursorborderradius: "2px",
			autohidemode: false,
			background: "#eef1f3",
			horizrailenabled: false,
		};
		select.styler({
			singleSelectzIndex: '0',
			selectSmartPositioning: false,
			onFormStyled: function () {
				_dropdown = $('.jq-selectbox__dropdown');
				_dropdown.find('li:not(.optgroup)').wrapInner('<span>');
			},
			onSelectOpened: function () {
				var _ul = $(this).find('.jq-selectbox__dropdown ul');
				_ul.niceScroll(settings);
			}
		});
	}

	function selectDataAll(tabActiveData) {
		select.empty();
		$.getJSON("/data/data.json", function (data) {
			select.prepend('<option></option>');
			$.each(data, function (key, data) {
				if (tabActiveData === key) {
					$.each(data, function (key, data) {
						let subdivision = key;
						selectFilterFaculty.append('<optgroup label="' + ((subdivision === "faculty") ? 'Факультеты:' : 'Институты:') + '" data-group="' + subdivision + '"></optgroup>');
						$.each(data, function (key, data) {
							let faculty = key;
							selectFilterFaculty.find('optgroup[data-group="' + subdivision + '"]').append('<option value="' + faculty + '">' + data['name'] + '</option>');
							$.each(data.training, function (index, value) {
								selectFilterTraining.append('<option value="' + value['code'] + '" data-faculty="' + faculty + '">' + value['name'] + '</option>');
							});
						});
					});
				}
			});
			select.trigger('refresh');
		});
		selectStyle();
	}


	tab.on('click', function () {
		tab.removeClass('tabs__toggle-item--active');
		$(this).addClass('tabs__toggle-item--active');
		result.fadeOut(100, function () {
			filterTabs.find('.tabs__toggle-link').remove();
			selectFilterFaculty.html("");
		});
		let tabActiveData = $(this).data('tab-active');
		selectDataAll(tabActiveData);
	})

	// Load result
	selectFilterFaculty.on("change", function () {
		let valElem = $(this).val();
		let tabActiveData = filterTabs.find('[data-tab-active].tabs__toggle-item--active').data('tab-active');
		result.fadeOut(100, function () {
			$(this).html("");
			filterTabs.find('.tabs__toggle-link').remove();
		});

		$.getJSON("/data/data.json", function (data) {
			selectFilterTraining.empty();
			selectFilterTraining.prepend('<option></option>');
			$.each(data, function (key, data) {
				if (tabActiveData === key) {
					$.each(data, function (key, data) {
						$.each(data, function (key, data) {
							if (key === valElem) {
								$.each(data.training, function (index, value) {
									selectFilterTraining.append('<option value="' + value['code'] + '" data-faculty="' + valElem + '">' + value['name'] + '</option>');
								});
							}
						});
					});
				}
			});
			selectFilterTraining.trigger('refresh');
		});
	});

	selectFilterTraining.on("change", function () {
		let valElem = $(this).val();
		let valFaculty = selectFilterFaculty.find('option:selected').text();
		let valTraining = $(this).find('option:selected').text();
		$.ajax({
			url: "../data/ajax/training.html",
			method: "get",
			dataType: "html",
			data: {code: valElem},
			success: function (data) {
				result.fadeOut(500, function () {
					result.html(data);
					result.find("[data-faculty-name]").text(valFaculty);
					result.find("[data-training-name]").text(valTraining);
					result.fadeIn(500);
					filterTabs.append('<li class="tabs__toggle-link"><a href="#" class="link link--arrow link--all">' +
						'<span class="link__text">Подробнее</span>' +
						'<span class="link__icon-wrap">' +
						'<svg class="link__arrow icon"><use xlink:href="/assets/icons/sprite.svg#arrow-link"></use></svg>' +
						'</span></a></li>');
				});
			}
		});
	});

	selectDataAll(tabActive);

});
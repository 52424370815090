(function () {
    var tabs = $('[data-tabs]');
    tabs.each(function () {
        var tab = $(this);
        var toggles = tab.find('[data-tab-active]');
        var tabList = tab.find('[data-tab]');

        toggles.on('click', function () {
            var target = $(this).data('tabActive');

            toggles.removeClass('tabs__toggle-item--active');
            $(this).addClass('tabs__toggle-item--active');

            tabList.each(function() {
                if($(this).data('tab') === target) {
                    $(this).addClass('tabs__body--active');
                } else {
                    $(this).removeClass('tabs__body--active');
                }
            })
        })
    })
})();
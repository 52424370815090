import slick from 'slick-carousel';

(function () {
    $(".slider.slider--main .slider__list").slick({
        lazyLoad: 'ondemand',// 'progressive', 'ondemand'
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        // autoplay: true,
        arrows: true,
        prevArrow: '',
        nextArrow: ".slider.slider--main .slider__next",
        dotsClass: 'slider__dots',
        customPaging: function (slider, i) {
            return '<span class="slider__dots-curr">' + (i + 1) + '</span><span class="slider__dots-all">' + slider.slideCount + '</span>';
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    autoplay: false
                }
            }
        ],
    });
})();


(function () {
    var slider = $('.slider.slider--default');

    if(slider.length === 0) {
        return
    }

    slider.each(function () {
        var slider = $(this);
        var slickSlider = null;

        function slickInit() {
            var nextArrow = slider.parent().find('.slider-arrow__next');
            var prevArrow = slider.parent().find('.slider-arrow__prev');

            slickSlider = slider.find(".slider__list").slick({
                lazyLoad: 'ondemand',
                slidesToShow: 6,
                slidesToScroll: 1,
                dots: false,
                arrows: true,
                responsive: [
                    {
                        breakpoint: 1367,
                        settings: {
                            slidesToShow: 5
                        }
                    },
                    {
                        breakpoint: 1201,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToShow: 3
                        }
                    },
                    {
                        breakpoint: 641,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                        }
                    }
                ],
                nextArrow: nextArrow,
                prevArrow: prevArrow,
            });
        }

        function initSliderDefault() {
            var width = $(window).width();

            if(width < 641 && !slider.hasClass('slider--partner')) {
                slickSlider && slickSlider.slick('unslick');
                slickSlider = null;
            } else if(!slickSlider) {
                slickInit();
            }
        }

        $(window).on('resize', initSliderDefault);

        initSliderDefault();
    })
})();

